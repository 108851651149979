<template>
  <div class="m-page-box">
    <div class="m-page-wrap">
      <ul class="header-wrap">
        <li class="item logo">
          <img src="../../assets/m-t-logo.png" alt="logo" />
        </li>
        <li class="item">首页</li>
      </ul>
      <ul class="image-box">
        <li class="box-left">
          <img src="../../assets/home_c.png" />
        </li>
        <li class="box-right">
          <img src="../../assets/logo_text_b.png" class="logo_text_b" />
          <span class="desc_text"
            >【九九交易行】是拥有官方认证的专业传奇游戏交易平台,目前与市场上大量的传奇游戏达成了深度合作，打通了技术壁垒，实现了安全高效的角色交易功能，保障用户的交易安全，永久承诺找回包赔。我们团队一直致力于打造安全、高效、专业的游戏虚拟道具交易平台，为用户提供专业、优质的交易体验。</span
          >
          <div class="down_btn" @click="downloadAndroid">
            <img src="../../assets/an.png" />
            <a>Android下载</a>
          </div>
        </li>
      </ul>
      <div class="footer-warp">
        <ul class="footer-content">
          <li class="logo item">
            <img src="../../assets/m-b-logo.png" alt="logo" />
          </li>
          <li class="item">联系方式：aml2077@163.com</li>
          <li class="item">公司地址：深圳市龙岗区龙城街道新联社区爱南路468-4号206-C01</li>
        </ul>
        <p class="tips">
          <span>Copyright 2023 艾米罗科技（深圳）有限公司 All Rights Reserved.</span>
          <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备18066307号-2</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import $Buryingpoint from './Buryingpoint';
export default {
  name: 'm_home',
  data() {
    return {
      deviceInfoStr:'',
      loginInfoStr:'',
      uid:''
    };
  },
  mounted(){
    let deviceInfoStr=sessionStorage.getItem('deviceInfoStr');
    if(deviceInfoStr) {
      let loginInfoStr =  sessionStorage.getItem('loginInfoStr');
      let uid = sessionStorage.getItem('uid');
      this.deviceInfoStr = deviceInfoStr;
      this.loginInfoStr = loginInfoStr;
      this.uid = uid;
    }

    // 如果是ios环境跳转到m站
    const isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isIOS) {
      window.location.href = 'https://m.jiaoyihang.com';
    }
  },
  methods: {
    ajax(eventname,properities) {
        let loginInfoStr = this.loginInfoStr;
        let loginInfo = JSON.parse(loginInfoStr);
        let {
            server_id,
            role,
            role_id,
            server_name,
            role_level,
            job_id,
            job_name
        } = loginInfo;
        let basicProperities = {
            servid:server_id,
            server_name:server_name,
            role_id:role_id,
            role_name:role,
            role_level:role_level,
            job_id:job_id,
            job_name:job_name,
            h5_ver:'1.4.2'
        };
        let edata = {
            eventname,// 上报事件名
            eventtype: 'track',
            uid:this.uid  ? this.uid  : '0', // 用户UID
            properities:{ ...basicProperities, ...properities }// 上报事件详情，根据不同事件上报内容不一样
        };
        $Buryingpoint.Burying(edata);
    },

    downloadAndroid () {
      if (this.deviceInfoStr) {
        this.ajax('download',{
          location:'官网'
        });
      }
      const link = document.createElement('a');
      link.href = this.downloadUrl;
      link.download = '九九交易行APP';
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
