import CryptoJS from 'crypto-js';
// import gameEnvName from './gameEnvName.js';
// import storage from './storage.js';
let Information = {};
let sdk_ver = '1.0.0';
let app_ver = '1.0.9';
let verStr = sessionStorage.getItem('verStr');
let ver = JSON.parse(verStr);
if(ver){
    sdk_ver = ver.sdkVersionName;
    app_ver = ver.appVersionName;
}

let sdk_app_id = 'dJ40beFLGXJ6';
let sdk_app_key = '91ad96c2b94aa61475378d38a8971df5';
let sdk_channel_id = 'channel_id';
sessionStorage.setItem('sdk_app_id',sdk_app_id);
sessionStorage.setItem('sdk_app_key',sdk_app_key);
sessionStorage.setItem('sdk_channel_id',sdk_channel_id);

window.addEventListener('error', function (e) {
    console.log(e);
    console.log(e.target);
});

if (!sessionStorage.getItem('sdk_app_id') && !sessionStorage.getItem('sdk_app_key')){
    Y();
}

function Y (){
    let isAndroid = null;
    let isIOS = null;
    var u = navigator.userAgent;
    isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
    isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (isAndroid) {
        console.log('isAndroid');
    } else if (isIOS) {
        if (window.webkit && window.webkit.messageHandlers.getIDFA !== undefined) {
            window.webkit.messageHandlers.getIDFA.postMessage('Javascript invoke OC');
            window.IOSInformation = IOSInformation;
        }
    }
    function IOSInformation (res){
        Information = JSON.parse(res);
    }
}

export default {
    Burying (key) {
        let deviceInfoStr = sessionStorage.getItem('deviceInfoStr') ;
        let deviceInfo = JSON.parse(deviceInfoStr);
        let gameEnvName = sessionStorage.getItem('gameEnvName')
        let type = gameEnvName == 'game-android' ? 'android' : 'ios';
        deviceInfo.type = type;
        let burying = JSON.stringify({
            appid: sessionStorage.getItem('sdk_app_id') || sessionStorage.getItem('appid'),
            sdk_ver: sdk_ver,
            app_ver: app_ver,
            channel:  sessionStorage.getItem('sdk_channel_id') || sessionStorage.getItem('gameid'),
            boxid:'boxid',
            device: {
                type: (deviceInfo && deviceInfo.type) || 'H5',
                model: (deviceInfo && deviceInfo.model) || isMobile(),
                id: (deviceInfo && deviceInfo.id) || uuid(),
                id1: 'id1',
                id2: 'id2',
                id3: 'id3',
                os: (deviceInfo && deviceInfo.os) || browserRedirect(),
                width: window.innerWidth,
                height: window.innerHeight,
                lang: (deviceInfo && deviceInfo.lang) || navigator.language
            },
            net_type: (deviceInfo && deviceInfo.net_type) || getNetworkType(),
            user: {
                id: key.uid
            },
            event: {
                time: Date.now() / 1000 | 0,
                name: key.eventname,
                type: key.eventtype
            },
            properities: key.properities
        });
        let headers = objKeySort(burying);
        const NODE_ENV = process.env.NODE_ENV;
        const SET_ENV = process.env.SET_ENV;
        // const ajaxAddressList = {
        //     'development':'//track-test.tj.996sdk.com/v1/event',
        //     'pre':'https://log-pre.tj.996Sdk.com/v1/event',
        //     'production':'https://log.tj.996sdk.com/v1/event'
        // };
        // if(SET_ENV && SET_ENV != 'undefined'){
        //     ajax('post', ajaxAddressList[SET_ENV], burying, headers);
        // }else{
        //     ajax('post', ajaxAddressList[NODE_ENV], burying, headers);
        // }
        // ajax('post', '//track-test.tj.996sdk.com/v1/event', burying, headers);
        ajax('post', 'https://log.tj.996sdk.com/v1/event', burying, headers);

        return burying;
    },
    // let data = {"net_type":"WIFI","app_ver":"SDKDemo1.0.0","appid":"ZykOLEtM3jav","channel":"123","sdk_ver":"1.2.2","device":{"type":"android","model":"HONOR:FNE-AN00","id1":"","id2":"f2eced5e3d7a124b","id3":"ca127fac-bba1-4657-94fa-3ac2c6bdfb1b","os":"12","width":"1080","height":"2316","lang":"zh","id":"ca127fac-bba1-4657-94fa-3ac2c6bdfb1b","net_type":"WIFI"}}
    Escalation () {
        let data = {
            net_type: '' || getNetworkType(),
            app_ver: app_ver,
            appid: sessionStorage.getItem('sdk_app_id') || sessionStorage.getItem('appid'),
            channel: sessionStorage.getItem('sdk_channel_id') || sessionStorage.getItem('gameid') || 'channel_id',
            sdk_ver: sdk_ver,
            device:{
                type: Information.type || 'H5',
                model: Information.model || isMobile(),
                id: Information.id || uuid(),
                id1: '',
                id2: '',
                id3: '',
                os: Information.os || browserRedirect(),
                width: window.innerWidth,
                height: window.innerHeight,
                lang: Information.lang || navigator.language,
                net_type: Information.net_type || getNetworkType(),
                idfv: Information.idfv || ''
            }
        };
        return encodeURIComponent(JSON.stringify(data));
    }
};

function isMobile () {
    var u = navigator.userAgent;
    if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
        return 'Android';
    } else if (u.indexOf('iPhone') > -1) {
        return 'iOS';
    } else if (u.indexOf('Windows Phone') > -1) {
        return 'Winphone';
    } else {
        return 'PC';
    }
}

function uuid () {
    let uuid996 = localStorage.getItem('uuid996' + sessionStorage.getItem('sdk_app_key'));
    let i = '';
    if (uuid996 == null) {
        i = guid();
        sessionStorage.setItem('sdk_app_id',sdk_app_id);('uuid996' + sessionStorage.getItem('sdk_app_key'), i);
    } else {
        i = uuid996;
    }
    return i;
}
function guid () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

function browserRedirect (){
    var sUserAgent = navigator.userAgent;
    var isWin = (navigator.platform === 'Win32') || (navigator.platform == 'Windows');
    var isMac = (navigator.platform === 'Mac68K') || (navigator.platform == 'MacPPC') || (navigator.platform == 'Macintosh') || (navigator.platform == 'MacIntel');
    if (isMac) {
        return 'Mac';
    }
    var isUnix = (navigator.platform == 'X11') && !isWin && !isMac;
    if (isUnix) {
        return 'Unix';
    }
    var isLinux = (String(navigator.platform).indexOf('Linux') > -1);
    if (isLinux) {
        return 'Linux';
    }
    if (isWin) {
        var isWin2K = sUserAgent.indexOf('Windows NT 5.0') > -1 || sUserAgent.indexOf('Windows 2000') > -1;
        if (isWin2K) {
            return 'Win2000';
        }
        var isWinXP = sUserAgent.indexOf('Windows NT 5.1') > -1 || sUserAgent.indexOf('Windows XP') > -1;
        if (isWinXP) {
            return 'WinXP';
        }
        var isWin2003 = sUserAgent.indexOf('Windows NT 5.2') > -1 || sUserAgent.indexOf('Windows 2003') > -1;
        if (isWin2003) {
            return 'Win2003';
        }
        var isWinVista = sUserAgent.indexOf('Windows NT 6.0') > -1 || sUserAgent.indexOf('Windows Vista') > -1;
        if (isWinVista) {
            return 'WinVista';
        }
        var isWin7 = sUserAgent.indexOf('Windows NT 6.1') > -1 || sUserAgent.indexOf('Windows 7') > -1;
        if (isWin7) {
            return 'Win7';
        }
        var isWin10 = sUserAgent.indexOf('Windows NT 10') > -1 || sUserAgent.indexOf('Windows 10') > -1;
        if (isWin10) {
            return 'Win10';
        }
        var isWin11 = sUserAgent.indexOf('Windows NT 11') > -1 || sUserAgent.indexOf('Windows 11') > -1;
        if (isWin11) {
            return 'Win11';
        }
    }
    return 'other';
}

function getNetworkType (){
    var ua = navigator.userAgent;
    var networkStr = ua.match(/NetType\/\w+/) ? ua.match(/NetType\/\w+/)[0] : 'NetType/other';
    networkStr = networkStr.toLowerCase().replace('nettype/', '');
    var networkType;
    switch (networkStr) {
    case 'wifi': networkType = 'wifi'; break;
    case '5g': networkType = '5G'; break;
    case '4g': networkType = '4G'; break;
    case '3g': networkType = '3G'; break;
    case '3gnet': networkType = '3G'; break;
    case '2g': networkType = '2G'; break;
    default: networkType = 'UNKNOWN';
    }
    return networkType;
}

function ajax (method, url, params, header) {
    var xhr = new XMLHttpRequest();
    xhr.open(method, url);
    Object.keys(header).forEach((key) => {
        xhr.setRequestHeader(key, header[key]);
    });
    if (method.toLowerCase() === 'get') {
        xhr.send(params);
    } else {
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(params);
    }
    xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
            // console.log(xhr.responseText);
        }
    };
}

function getRamNumber () {
    var result = '';
    for (var i = 0; i < 16; i++) {
        result += Math.floor(Math.random() * 16).toString(16);
    }

    return result.toUpperCase();
}

function objKeySort (body) {
    let headers = {
        'x-996sdk-appid': sessionStorage.getItem('sdk_app_id'),
        'x-996sdk-algo':  'MD5',
        'x-996sdk-nonce':  getRamNumber(),
        'x-996sdk-timestamp':  Date.now() / 1000 | 0
    };
    let xargs = [
        'POST',
        '/v1/event'
    ];
    Object.keys(headers).sort().forEach((key) => {
        xargs.push(key + '=' + headers[key]);
    });
    xargs.push(body);
    xargs.push('key=' + sessionStorage.getItem('sdk_app_key'));
    headers['x-996sdk-sign'] = CryptoJS.MD5(xargs.join('\n')).toString();
    return headers;
}
